const colors = {
  transparent: 'transparent',
  labelColor: '#767676',
  lightBorder: '#f1f4f6',
  inactiveField: '#F7F8FB',
  inactiveButton: '#b7dbdd',
  inactiveIcon: '#EBEBEB',
  primaryHover: '#54BC44',
  secondary: '#00A0FF',
  secondaryHover: '#112045',
  yellow: '#FFA740',
  yellowHover: '#F6C416',
  borderColor: '#E5E5E5',
  borderColorTwo: '#EAE9F2',
  borderColorThree: '#EDF0F4',
  black: '#000000',
  white: '#ffffff',
  light: '#FAF7FA',
  gray: '#E4E4E4',
  primary: '#3CB44C',
  primaryLight: '#8CC43C',
  headingColor: '#0F2137',
  quoteText: '#343D48',
  menu: '#02073E',
  twitterColor: '#54BC44',
  textColor: '#343D48',
  linkColor: '#02073E',
  shadow: 'rgba(38, 78, 118, 0.1)',
};

export default colors;
